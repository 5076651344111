ion-icon.large {
  width: 100%;
  height: 5vh;
  margin-top: 1vh;
}

ion-card-header.icon {
  padding-top: 0;
}

ion-card {
  @media (prefers-color-scheme: dark) {
    background: var(--ion-color-light);
  }
}

#icons {
  position: relative;

  .delete {
    width: 4vh;
    position: absolute;
    right: 1vh;
    top: 0;
    color: var(--ion-color-danger);
  }
}
