#meetings {
  display: inline-grid;
  grid-template-columns: auto;
  width: 100%;
  align-items: center;
}

@media (min-width: 700px) {
  #meetings{
    grid-template-columns: auto auto;
  }
}

@media (min-width: 1100px) {
  #meetings{
    grid-template-columns: auto auto auto;
  }
}

@media (min-width: 1400px) {
  #meetings{
    grid-template-columns: auto auto auto auto;
  }
}
