#settings-page {
  ion-content {
    position: relative;
  }

  form {
    position: absolute;
    width: 100vw;
    top: 50%;
    transform: translate(-50%, -50%);
    max-width: 600px;
    left: 50%
  }

  .login, .register {
    width: 100%;
    text-align: center;
    cursor: pointer;
  }
}
